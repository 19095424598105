import classNames from 'classnames';
import {CSSProperties, useCallback} from 'react';
import {DialogButton, DialogStyle} from '../Contract';

type SimpleDialogButtonProps = {
  onClick: () => void;
} & Omit<DialogStyle, 'backgroundColor'> &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  Omit<DialogButton, 'id'>;

const SimpleDialogButton: React.FC<SimpleDialogButtonProps> = ({
  className,
  title,
  style,

  mainColor,
  oppositeColor,
  mainTextColor,

  onClick,

  ...rest
}) => {
  const getStyle = useCallback((): CSSProperties => {
    switch (style) {
      case 'blank':
        return {
          color: mainTextColor,
          border: `1px solid ${mainColor}`,
        };

      default:
      case 'normal':
        return {
          color: oppositeColor,
          background: mainColor,
        };
    }
  }, [mainColor, mainTextColor, oppositeColor, style]);

  return (
    <button
      className={classNames(
        'text-center cursor-pointer px-3 py-2 rounded-lg hover:scale-105 transition ease-in-out',
        className,
      )}
      style={getStyle()}
      onClick={onClick}
      {...rest}
    >
      <span className="z-30 pointer-events-none">{title}</span>
    </button>
  );
};

export default SimpleDialogButton;
