import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import {
  TournamentProgressbarCommunicatorGettingEvent,
  TournamentProgressbarCommunicatorMarkTaskGettingEvent,
  TournamentProgressbarCommunicatorSendingEvent,
  TournamentProgressbarCommunicatorSendingEventTaskCollected,
  TournamentProgressbarCommunicatorSendingEventTaskCompleted,
} from '../../../pages/scriptIntegrations/tournamentProgressbar/CommonTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';

interface TournamentProgressbarIntegrationProps {
  markTaskAsCompleted: (id: string) => void;
  refresh: () => void;
}

export interface TournamentProgressbarIntegrationRef {
  onLoaded: () => void;
  onTaskCompleted: (id: string) => void;
  onTaskCollected: (id: string) => void;
}

const TournamentProgressbarEventHandler = forwardRef<
  TournamentProgressbarIntegrationRef,
  TournamentProgressbarIntegrationProps
>(({refresh, markTaskAsCompleted}, ref) => {
  const eventMessangerRef =
    useRef<
      EventSystemRefProps<
        | TournamentProgressbarCommunicatorSendingEvent
        | TournamentProgressbarCommunicatorSendingEventTaskCompleted
        | TournamentProgressbarCommunicatorSendingEventTaskCollected
      >
    >(null);

  const incomingEventsHandler = useCallback(
    (
      message:
        | TournamentProgressbarCommunicatorGettingEvent
        | TournamentProgressbarCommunicatorMarkTaskGettingEvent,
    ) => {
      switch (message.event) {
        case 'markTaskAsCompleted':
          markTaskAsCompleted(message.id);
          return;
        case 'refresh':
          refresh();
          return;
      }
    },
    [markTaskAsCompleted, refresh],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
    onTaskCompleted(id: string) {
      eventMessangerRef.current?.sendMessage({
        event: 'onTaskCompleted',
        id,
      });
    },
    onTaskCollected(id: string) {
      eventMessangerRef.current?.sendMessage({
        event: 'onTaskCollected',
        id,
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      | TournamentProgressbarCommunicatorGettingEvent
      | TournamentProgressbarCommunicatorMarkTaskGettingEvent,
      | TournamentProgressbarCommunicatorSendingEvent
      | TournamentProgressbarCommunicatorSendingEventTaskCompleted
      | TournamentProgressbarCommunicatorSendingEventTaskCollected
    >
      componentName="tournamentProgressbar"
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default TournamentProgressbarEventHandler;
