import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Achievement from '../../pages/scriptIntegrations/achievement/Achievement';
import SuspenseLayout from '../layouts/basicLayout/SuspenseLayout';
import TournamentTable from '../../pages/scriptIntegrations/tournamentTable/SimpleTournamentTable';
import GameTournamentTable from '../../pages/scriptIntegrations/tournamentTable/GameTournamentTable';
import SaveWrapper from '../../pages/scriptIntegrations/save/SaveWrapper';
import StatisticsWrapper from '../../pages/scriptIntegrations/statistics/StatisticsWrapper';
import DailyQuestGameWrapper from '../../pages/scriptIntegrations/dailyQuest/DailyQuestGameWrapper';
import DailyQuestSimpleWrapper from '../../pages/scriptIntegrations/dailyQuest/DailyQuestSimpleWrapper';
import TournamentProgressbarSimpleWrapper from '../../pages/scriptIntegrations/tournamentProgressbar/TournamentProgressbarSimpleWrapper';
import IceBreakerSimpleWrapper from '../../pages/scriptIntegrations/iceBreaker/IceBreakerSimpleWrapper';
import UiExamples from '../../pages/uiExamples/UiExamples';
import JetpackWrapper from '../../pages/scriptIntegrations/jetpack/JetpackWrapper';
import BlockBlockerGameWrapper from '../../pages/scriptIntegrations/blockBlocker/BlockBlockerGameWrapper';
import DialogWrapper from '../../pages/scriptIntegrations/dialog/DialogWrapper';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          {/* <Route index element={<HereIntegrationWrapper />} />
          <Route
            path="/demo/chest"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 3,
                }}
              />
            }
          />
          <Route
            path="/demo/constructor"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 4,
                }}
              />
            }
          />
          <Route
            path="/demo/tournament"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 6,
                }}
              />
            }
          />
          <Route
            path="/demo/fight"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 2,
                }}
              />
            }
          />
          <Route
            path="/demo/onboarding"
            element={
              <IntegrationDemoWrapper
                loadData={{
                  scene: 10,
                }}
              />
            }
          />
          <Route path="/testintegration" element={<HereIntegrationWrapper />} />
          <Route path="/logout" element={<Logout />} /> */}
          <Route path="/uiExample" element={<UiExamples />} />
        </Route>
      </Route>

      <Route path="/components">
        <Route element={<SuspenseLayout />}>
          {/* <Route path="/components/chest" element={<ScriptChestScene />} /> */}

          <Route path="/components/jetpack">
            <Route path=":type/:id" element={<JetpackWrapper />} />
          </Route>

          <Route
            path="/components/blocker"
            element={<BlockBlockerGameWrapper />}
          />

          <Route path="/components/dialog">
            <Route path=":type/:id" element={<DialogWrapper />} />
          </Route>

          <Route path="/components/achievement" element={<Achievement />} />
          <Route
            path="/components/tournamentTable"
            element={<TournamentTable />}
          />
          <Route
            path="/components/tournamentTableGame"
            element={<GameTournamentTable />}
          />
          <Route
            path="/components/dailyQuestGame"
            element={<DailyQuestGameWrapper />}
          />
          <Route
            path="/components/dailyQuestSimple"
            element={<DailyQuestSimpleWrapper />}
          />
          <Route
            path="/components/tournamentProgressbarSimple"
            element={<TournamentProgressbarSimpleWrapper />}
          />
          <Route
            path="/components/iceBreaker"
            element={<IceBreakerSimpleWrapper />}
          />

          <Route path="/components/saver" element={<SaveWrapper />} />
          <Route
            path="/components/statistics"
            element={<StatisticsWrapper />}
          />
        </Route>
      </Route>

      {/* <Route path="/scene">
        <Route
          element={<SuspenseLayout background='url("/backgrounds/2.jpg")' />}
        >
          <Route path="/scene/test" element={<FightSceneTest />} />
        </Route>
      </Route> */}
    </Routes>
  </BrowserRouter>
);
export default AppRouter;
