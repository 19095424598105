import {DialogProps, DialogStyle} from '../../../components/ui/dialog/Contract';
import SimpleDialog from '../../../components/ui/dialog/simple/SimpleDialog';

export type DialogTypes = 'simple';

interface DialogFactoryProps {
  type: DialogTypes;
  properties: DialogProps;
  style: DialogStyle;
}

const DialogFactory: React.FC<DialogFactoryProps> = ({
  type,
  properties,
  style,
}) => {
  switch (type) {
    case 'simple':
      return <SimpleDialog props={properties} style={style} />;
  }
};

export default DialogFactory;
