import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import classNames from 'classnames';
import {BsChevronDown} from 'react-icons/bs';
import {CheckListUiProps} from '../Types';
import Button, {ButtonStyle} from '../../../form/button/Button';
import {ButtonSize} from '../../../buttons/yellowShort/StemmyYellowButton';
import StemmyProgressBar3 from '../../../progressBars/progressBar3/ProgressBar3';
import './CheckListUiSimple.scoped.css';

const CheckListUiSimple: React.FC<CheckListUiProps> = ({
  onLoaded = () => {},
  list,
  onAllTasksChecked = () => {},
  onTaskChecked,
  onButtonClick,
}) => {
  const [localCheckList, setLocalCheckList] = useState(list);

  const changeCompleted = (index: number) => {
    const newData = [...localCheckList];
    newData[index].completed = !newData[index].completed;
    setLocalCheckList(newData);
  };

  useEffect(() => {
    onLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localCheckList.filter((l) => l.completed).length === list.length) {
      console.log('all tasks checked');
      onAllTasksChecked();
    }
  }, [localCheckList, list.length, onAllTasksChecked]);

  return (
    <div className="w-full relative z-50 flex justify-center items-center font-inter">
      <div className="max-w-3xl w-full bg-white rounded-[20px] shadow-[0px_5px_25px_rgba(0,_0,_0,_0.1)] pl-[4%] pr-[3%] sm:pr-[7%] py-[5%]">
        <h2 className="text-[#0C79FE] font-bold flex justify-between items-center pl-[2%] pb-[3%] cursor-pointer text-game-xl">
          First Steps <BsChevronDown className="text-[#007AFF]" />
        </h2>
        <div className="flex flex-col text-game-base sm:text-game-sm">
          <div className="pb-[6%]">Get ready to Tango!</div>
          <div className="px-[5%] sm:px-0">
            <StemmyProgressBar3
              completed={localCheckList.filter((l) => l.completed).length}
              total={localCheckList.length}
            />
          </div>
          <div className="flex flex-col pt-[4%] pb-[6%]">
            {localCheckList.map((l, index) => {
              return (
                <div
                  key={index}
                  className="flex pb-[1%] cursor-pointer"
                  onClick={() => {
                    changeCompleted(index);
                    if (l.completed) onTaskChecked(l.id);
                  }}
                >
                  <div className="max-w-[6%] w-full flex justify-center items-start">
                    <div
                      className={classNames(
                        'border-2 rounded-full aspect-square flex justify-center items-center w-full',
                        l.completed && 'border-[#5DC466]',
                      )}
                    >
                      <AnimatePresence>
                        {l.completed && (
                          <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 0.3}}
                            exit={{opacity: 0}}
                            className="bg-[#5DC466] rounded-full aspect-square w-2/3 sm:w-[78%] lg:w-4/5"
                          />
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                  <div className="flex flex-col text-game-base sm:text-game-sm w-full border-b pl-[2%]">
                    <div>{l.title}</div>
                    <div className="text-[#8E8E92] text-game-base sm:text-game-xs pb-[1%]">
                      {l.group}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            btnSize={ButtonSize.BASE}
            buttonStyle={ButtonStyle.LightBlue}
            textStyles="font-inter font-normal text-game-base sm:text-game-sm py-[2%]"
            onClick={() => onButtonClick()}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CheckListUiSimple;
