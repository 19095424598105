import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import {
  BlockBlockerCommunicatorGettingEvent,
  BlockBlockerCommunicatorSendingEvent,
} from '../../../pages/scriptIntegrations/blockBlocker/CommonTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';

interface BlockBlockerIntegrationProps {
  refresh: () => void;
}

export interface BlockBlockerIntegrationRef {
  onLoaded: () => void;
  onButtonClicked: () => void;
}

const BlockBlockerEventHandler = forwardRef<
  BlockBlockerIntegrationRef,
  BlockBlockerIntegrationProps
>(({refresh}, ref) => {
  const eventMessangerRef =
    useRef<EventSystemRefProps<BlockBlockerCommunicatorSendingEvent>>(null);

  const incomingEventsHandler = useCallback(
    (message: BlockBlockerCommunicatorGettingEvent) => {
      switch (message.event) {
        case 'refresh':
          refresh();
          return;
      }
    },
    [refresh],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
    onButtonClicked() {
      eventMessangerRef.current?.sendMessage({
        event: 'onButtonClicked',
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      BlockBlockerCommunicatorGettingEvent,
      BlockBlockerCommunicatorSendingEvent
    >
      componentName="blockBlocker"
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default BlockBlockerEventHandler;
