import React from 'react';
import {motion} from 'framer-motion';
import useSmoothNumberChange from '../../styles/smoothNumberChange';

export interface StemmyProgressBar3Props {
  total: number;
  completed: number;
}

const StemmyProgressBar3: React.FC<StemmyProgressBar3Props> = ({
  total,
  completed,
}) => {
  const smoothPercent = useSmoothNumberChange(total, completed);

  return (
    <div className="w-full relative h-[0.6vw] z-50">
      {/* незаполненная задняя часть прогресс бара */}
      <div className="absolute top-0 left-0 w-full h-full rounded-[10px] bg-[#C8B6DB]"></div>
      {/* заполненная */}
      <motion.div
        transition={{duration: 0.5, ease: 'backInOut'}}
        className="absolute top-0 left-0 w-full h-full rounded-[10px] bg-[#5D308D]"
        animate={{
          width: `${(completed / total) * 100}%`,
        }}
      >
        <div className="w-[1.2vw] h-[200%] rounded-full absolute right-0 -top-1/2 flex justify-center items-center">
          <div className="relative rounded-lg -top-[120%] -right-1/2 p-[25%] text-game-base lg:text-game-xs text-[#391E56] font-inter font-bold">
            <span>{smoothPercent}%</span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
export default StemmyProgressBar3;
