import React from 'react';
import {
  OnboardingJetpackProps,
  OnboardingJetpackRef,
} from '../../../components/animatedCharacters/onboardingJetpack/Contract';
import ReadyPlayerMeJetpack from '../../../components/animatedCharacters/onboardingJetpack/ReadyPlayerMeJetpack';
import RobotJetpack from '../../../components/animatedCharacters/onboardingJetpack/RobotJetpack';
import SuitGirlJetpack from '../../../components/animatedCharacters/onboardingJetpack/SuitGirlJetpack';
import SuitManJetpack from '../../../components/animatedCharacters/onboardingJetpack/SuitManJetpack';

export type JetpackTypes = 'suit' | 'girl' | 'robot' | 'readyPlayerMe';

interface JetpackFactoryProps {
  type: JetpackTypes;
  properties: OnboardingJetpackProps;
}

const JetpackFactory = React.forwardRef<
  OnboardingJetpackRef,
  JetpackFactoryProps
>(({type, properties}, ref) => {
  switch (type) {
    case 'suit':
      return <SuitManJetpack {...properties} ref={ref} />;
    case 'girl':
      return <SuitGirlJetpack {...properties} ref={ref} />;
    case 'robot':
      return <RobotJetpack {...properties} ref={ref} />;
    case 'readyPlayerMe':
      return <ReadyPlayerMeJetpack {...properties} ref={ref} />;
  }
});

export default JetpackFactory;
