/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState} from 'react';
import CheckList from '../../components/checkList/CheckList';
import CheckListSimpleWrapper from '../scriptIntegrations/checkList/CheckListSimpleWrapper';

const UiExamples: React.FC = () => {
  const [checkList, setCheckList] = useState([
    {
      title: 'First One',
      id: 'asd213sg',
      group: 'Reminders',
      completed: true,
    },
    {
      title: 'Second One',
      id: '435dfg',
      group: 'Reminders',
      completed: false,
    },
    {
      title: 'Third One',
      id: 'jgh876',
      group: 'Reminders',
      completed: false,
    },
    {
      title: 'Fourth One',
      id: '93fg756',
      group: 'Reminders',
      completed: false,
    },
  ]);

  return (
    <div
      className="w-full h-full relative overflow-hidden text-game-base"
      style={{
        backgroundColor: '#000',
        backgroundSize: 'cover',
      }}
    >
      <h1 className="text-white flex justify-center">UI examples</h1>
      {/* <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900">
        <div className="text-white">1) StemmyYellowAnswer:</div>
        <StemmyYellowAnswer answer={{text: 'Test', id: 'test'}} />
      </div> */}
      {/* <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">2) StemmyPurpleButton:</div>
        <StemmyPurpleButton
          answer={{
            answer: 'Test',
            answerId: 'testads',
          }}
          onClick={() => {}}
        />
      </div> */}
      {/* <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">3) StemmyYellowButton:</div>
        <StemmyYellowButton answer="Test" />
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">4) Form Buttons:</div>
        <div className="flex gap-2">
          <Button buttonStyle={ButtonStyle.Yellow}>Test</Button>
          <Button buttonStyle={ButtonStyle.Purple}>Test</Button>
          <Button buttonStyle={ButtonStyle.Green}>Test</Button>
          <Button buttonStyle={ButtonStyle.Red}>Test</Button>
          <Button buttonStyle={ButtonStyle.Blue}>Test</Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">5) InfoPopupAlert:</div>
        <div className="text-white relative">
          <InfoPopupAlert>Test</InfoPopupAlert>
        </div>
      </div> */}
      {/* <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">6) StemmyDialogView:</div>
        <StemmyDialogView
          dialog={{
            question: 'Test question',
            answers: [
              {answer: 'test1', answerId: 'sdff'},
              {answer: 'test2', answerId: 'sdff2'},
              {answer: 'test3', answerId: 'sdff3'},
            ],
            onClicked: () => {},
          }}
          isShuffleAnswers={false}
          onDialogAnswered={() => {}}
        />
      </div> */}
      {/* <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">7) StemmyInformingView:</div>
        <StemmyInformingView>Test</StemmyInformingView>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">8) StemmyInformingField:</div>
        <StemmyInformingField>Test</StemmyInformingField>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">9) StemmyTextField:</div>
        <StemmyTextField>
          <p>Test</p>
        </StemmyTextField>
      </div>
      <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">10) StemmyTextFieldWithHead:</div>
        <StemmyTextFieldWithHead question="Test" />
      </div>
      <div className="flex gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white">11) LeaderboardPopup:</div>
        <div className="relative w-full">
          <LeaderboardPopup
            active={true}
            prefixId="asddasas"
            id="dummy_leaderboard"
            leaderboard={{
              title: 'Test',
              text: 'text',
              lines: [
                {
                  place: 1,
                  name: 'name',
                  customProperties: [
                    {
                      value: '4',
                    },
                  ],
                },
              ],
            }}
            element={{
              id: '3554364256543543',
              externalbuttons: [
                {
                  id: 'next',
                  isFinishOnClick: true,
                  buttonStyle: ButtonStyle.Purple,
                  className: 'text-game-xl',
                  title: 'next',
                },
              ],
            }}
          />
        </div>
      </div>

      <div className="flex gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white w-1/3">12) RegistrationPopup:</div>
        <RegistrationPopup />
      </div>

      <div className="flex gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-slate-900 mt-2">
        <div className="text-white w-1/3">13) Game popup:</div>
        <div className="w-full aspect-video relative">
          <GamePopup
            id="test1"
            active
            prefixId="test"
            elements={[
              {
                id: 'task1',
                title: 'difference',
                windowSize: WidnowSize.Big,
                buttonsLayout: ButtonsLayout.TWO_COLUMNS,

                externalbuttons: [
                  {
                    id: '1',
                    title: 'no-documents',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                  {
                    id: '2',
                    title: 'transfer-in-any',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                  {
                    id: '3',
                    title: 'buy-goods',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                  {
                    id: '4',
                    title: 'you-can-cancel',
                    clickDelay: 1000,
                    isCoorrectAnswer: true,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                ],
              },
              {
                id: 'task2',
                title: 'How-can-I-regain',
                windowSize: WidnowSize.Auto,
                buttonsLayout: ButtonsLayout.VERTICAL,

                innerButtons: [
                  {
                    id: '1',
                    title: 'The-account',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg w-full',
                  },
                  {
                    id: '2',
                    title: 'By-calling',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg w-full',
                  },
                ],
                // fallback: {
                //   id: '071E7F3A-3AC2-4108-87F7-A34858150000',
                //   title: t('Save-a-12-word'),
                //   nextButton: {
                //     title: t('ok-1'),
                //     className: 'mt-3',
                //   },
                // },
              },
              {
                id: 'task3',
                title: 'Can-you-transfer-money',
                windowSize: WidnowSize.Big,
                buttonsLayout: ButtonsLayout.TWO_COLUMNS,

                externalbuttons: [
                  {
                    id: '1',
                    title: 'Yes-he-get',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                  {
                    id: '2',
                    title: 'Yes-but-if-he',
                    clickDelay: 1000,
                    isCoorrectAnswer: true,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                  {
                    id: '3',
                    title: 'Yes-he-can-receive',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                  {
                    id: '4',
                    title: 'I-cannot-transfer-money',
                    clickDelay: 1000,
                    isCoorrectAnswer: false,
                    isNextOnClick: true,
                    className: 'text-game-lg',
                  },
                ],
              },
            ]}
          />
        </div>
      </div> */}

      {/* <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-purple-600 mt-2">
        <div className="text-white w-1/3">14) DailyQuest:</div>
        <div className="relative w-full px-[5%]">
          <DailyQuestUiSimple
            onLoaded={() => {
              console.log('onLoaded');
            }}
            onTaskCompleted={() => {
              console.log('TaskCompleted');
            }}
            onTaskCollected={() => {
              console.log('TaskCollected');
            }}
            dailyQuests={[
              {
                id: 'fsee23',
                title: 'FINISH 150 ORDERS',
                img: './assets/dailyQuest/daily-quest-star.png',
                total: 150,
                completed: 50,
                buttonTitle: 'Collect 150',
                buttonOnClick: () => {
                  console.log('button collect 150 is clicked');
                },
              },
              {
                id: '324der',
                title: 'FINISH 77 ORDERS',
                img: './assets/dailyQuest/daily-quest-star.png',
                total: 77,
                completed: 50,
                buttonTitle: 'Collect 77',
                buttonOnClick: () => {
                  console.log('button collect 77 is clicked');
                },
              },
              {
                id: '45dfhgh',
                title: 'FINISH 42 ORDERS',
                img: './assets/dailyQuest/daily-quest-star.png',
                total: 42,
                completed: 42,
                buttonTitle: 'Collect 42',
                buttonOnClick: () => {
                  console.log('button collect 42 is clicked');
                },
              },
              {
                id: '564fgh',
                title: 'FINISH 51 ORDERS',
                img: './assets/dailyQuest/daily-quest-star.png',
                total: 51,
                completed: 50,
                buttonTitle: 'Collect 51',
                buttonOnClick: () => {
                  console.log('button collect 51 is clicked');
                },
              },
            ]}
          />
        </div>
      </div> */}

      <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-4 gap-x-2 lg:gap-x-8 items-center justify-items-center bg-purple-600 mt-2">
        <div className="text-white w-1/3">15) CheckList:</div>
        <div className="py-4 w-full">
          <CheckListSimpleWrapper />
        </div>
      </div>
    </div>
  );
};

export default UiExamples;
