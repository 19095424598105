import React from 'react';
import {motion} from 'framer-motion';
import './StemmyProgressBarGreen.scoped.css';

export interface StemmyProgressBarGreenProps {
  completed: number;
  total: number;
}

const StemmyProgressBarGreen: React.FC<StemmyProgressBarGreenProps> = ({
  completed,
  total,
}) => {
  return (
    <div className="w-full relative h-[6vw] md:h-[2vw] rounded-[5px] md:rounded-[15px]">
      {/* незаполненная задняя часть прогресс бара, серая */}
      <div className="overflow-hidden absolute top-0 left-0 w-full h-full rounded-[5px] md:rounded-[15px]">
        <div className="absolute top-0 left-0 w-full h-full bg-[#4C435D]"></div>
        <div className="absolute -top-[6%] left-0 w-full h-full bg-[#574E6A] rounded-[5px] md:rounded-[15px]"></div>
        <div className="absolute left-0 w-full h-full bg-[#5D536F] -top-1/2 rounded-[48.8702px]"></div>
      </div>
      {/* заполненная зеленая */}
      <motion.div
        transition={{duration: 0.5, ease: 'backInOut'}}
        className="absolute top-0 left-0 h-full rounded-[5px] md:rounded-[15px] overflow-hidden"
        animate={{
          width: `${(completed / total) * 100}%`,
        }}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-complete-progress-bar rounded-[5px] md:rounded-[15px]"></div>
        <div className="absolute -top-[6%] left-0 w-full h-full bg-complete-progress-bar-upper rounded-[5px] md:rounded-[15px]"></div>
        <div className="absolute left-0 w-full h-full bg-complete-progress-bar-upper2 -top-1/2 rounded-[48.8702px]"></div>
      </motion.div>
    </div>
  );
};
export default StemmyProgressBarGreen;
