import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {BasicElement} from '../../helpers/BasicElement';
import EventsMessanger, {
  EventSystemRefProps,
} from '../eventSystem/EventsMessanger';
import {DialogData, DialogStyle} from '../ui/dialog/Contract';

interface DialogGettingBasicEvent {
  event: 'clear';
}

interface DialogGettingSetupEvent {
  event: 'setup';
  payload: DialogData;
}

interface DialogGettingStyleEvent {
  event: 'setStyle';
  payload: DialogStyle;
}

interface DialogSendingBasicEvent {
  event: 'onLoaded' | 'onCloseButtonClick';
}

interface DialogSendingSizeEvent {
  event: 'widthChanged' | 'heightChanged';
  payload: number;
}

interface DialogSendingButtonEvent {
  event: 'buttonClicked';
  payload: {
    buttonId: string;
  };
}

export interface DialogScriptIntegrationRef {
  onHeightChanged: (val: number) => void;
  onWidthChanged: (val: number) => void;

  onButtonPressed: (id: string) => void;
  onCloseButtonPressed: () => void;
}

interface DialogScriptIntegrationProps extends BasicElement {
  componentId: string;

  clearDialog: () => void;
  updateDialog: (data: DialogData) => void;
  updateStyle: (data: DialogStyle) => void;
}

const DialogScriptIntegration = forwardRef<
  DialogScriptIntegrationRef,
  DialogScriptIntegrationProps
>(({componentId, clearDialog, updateDialog, updateStyle, children}, ref) => {
  const eventMessangerRef =
    useRef<
      EventSystemRefProps<
        | DialogSendingBasicEvent
        | DialogSendingSizeEvent
        | DialogSendingButtonEvent
      >
    >(null);

  const incomingEventsHandler = useCallback(
    (
      message:
        | DialogGettingBasicEvent
        | DialogGettingSetupEvent
        | DialogGettingStyleEvent,
    ) => {
      switch (message.event) {
        case 'clear':
          clearDialog();
          return;
        case 'setStyle':
          updateStyle(message.payload);
          return;
        case 'setup':
          updateDialog(message.payload);
      }
    },
    [clearDialog, updateDialog, updateStyle],
  );

  useImperativeHandle(ref, () => ({
    onHeightChanged(val) {
      eventMessangerRef.current?.sendMessage({
        event: 'heightChanged',
        payload: val,
      });
    },
    onWidthChanged(val) {
      eventMessangerRef.current?.sendMessage({
        event: 'widthChanged',
        payload: val,
      });
    },
    onButtonPressed(id) {
      eventMessangerRef.current?.sendMessage({
        event: 'buttonClicked',
        payload: {
          buttonId: id,
        },
      });
    },
    onCloseButtonPressed() {
      eventMessangerRef.current?.sendMessage({
        event: 'onCloseButtonClick',
      });
    },
  }));

  const [, setComponentsReady] = useState({
    messanger: false,
    master: false,
  });

  const onLoaded = useCallback((source: 'messanger' | 'master') => {
    setComponentsReady((old) => {
      const updated = {...old, [source]: true};

      if (updated.master && updated.messanger) {
        eventMessangerRef.current?.sendMessage({
          event: 'onLoaded',
        });
      }
      return updated;
    });
  }, []);

  useEffect(() => {
    onLoaded('master');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <EventsMessanger<
        | DialogGettingBasicEvent
        | DialogGettingSetupEvent
        | DialogGettingStyleEvent,
        | DialogSendingBasicEvent
        | DialogSendingSizeEvent
        | DialogSendingButtonEvent
      >
        componentName={componentId}
        myRef={eventMessangerRef}
        onMessage={incomingEventsHandler}
        onLoaded={() => onLoaded('messanger')}
      />
      {children}
    </div>
  );
});

export default DialogScriptIntegration;
