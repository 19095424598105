import {useEffect, useState} from 'react';

// ф-ия в 5 шагов (условно плавно) меняет процент выполненного задания
const useSmoothNumberChange = (total: number, completed: number) => {
  const [lastPercent, setLastPercent] = useState(100); // для анимации изменения числа процентов, в useEffect добавил изменение постепенное в 5 шагов. Чтобы казалось, будто число плавно меняется

  useEffect(() => {
    const currentPercent = Math.round((completed / total) * 100);
    const timer1 = setTimeout(() => {
      setLastPercent(
        currentPercent > lastPercent
          ? Math.round(lastPercent + (currentPercent - lastPercent) / 5)
          : Math.round(lastPercent - (lastPercent - currentPercent) / 5),
      );
    }, 100);
    const timer2 = setTimeout(() => {
      setLastPercent(
        currentPercent > lastPercent
          ? Math.round(lastPercent + 2 * ((currentPercent - lastPercent) / 5))
          : Math.round(lastPercent - 2 * ((lastPercent - currentPercent) / 5)),
      );
    }, 200);
    const timer3 = setTimeout(() => {
      setLastPercent(
        currentPercent > lastPercent
          ? Math.round(lastPercent + 3 * ((currentPercent - lastPercent) / 5))
          : Math.round(lastPercent - 3 * ((lastPercent - currentPercent) / 5)),
      );
    }, 300);
    const timer4 = setTimeout(() => {
      setLastPercent(
        currentPercent > lastPercent
          ? Math.round(lastPercent + 4 * ((currentPercent - lastPercent) / 5))
          : Math.round(lastPercent - 4 * ((lastPercent - currentPercent) / 5)),
      );
    }, 400);
    const timer5 = setTimeout(() => {
      setLastPercent(currentPercent);
    }, 500);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
    };
  }, [total, completed]);

  return lastPercent;
};
export default useSmoothNumberChange;
