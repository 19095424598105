import {useCallback} from 'react';
import {DailyQuestUiProps} from '../../ui/dailyQuest/Types';

interface DailyQuestUiWrapperProps {
  userId?: string;
  uiElement: React.FC<DailyQuestUiProps>;

  onLoaded: () => void;
  onTaskCompleted: (id: string) => void;
  onTaskCollected: (id: string) => void;
}

const DailyQuestUiWrapper: React.FC<DailyQuestUiWrapperProps> = ({
  uiElement,
  onLoaded,
  onTaskCompleted,
  onTaskCollected,
}) => {
  const getContent = useCallback(() => {
    return uiElement({
      onLoaded,
      onTaskCompleted,
      onTaskCollected,
      dailyQuests: [
        {
          id: 'fsee23',
          title: 'FINISH 150 ORDERS',
          img: './assets/dailyQuest/daily-quest-star.png',
          total: 150,
          completed: 50,
          buttonTitle: 'Collect 150',
          buttonOnClick: () => {
            console.log('button collect 150 is clicked');
          },
        },
        {
          id: '324der',
          title: 'FINISH 77 ORDERS',
          img: './assets/dailyQuest/daily-quest-star.png',
          total: 77,
          completed: 50,
          buttonTitle: 'Collect 77',
          buttonOnClick: () => {
            console.log('button collect 77 is clicked');
          },
        },
        {
          id: '45dfhgh',
          title: 'FINISH 42 ORDERS',
          img: './assets/dailyQuest/daily-quest-star.png',
          total: 42,
          completed: 42,
          buttonTitle: 'Collect 42',
          buttonOnClick: () => {
            console.log('button collect 42 is clicked');
          },
        },
        {
          id: '564fgh',
          title: 'FINISH 51 ORDERS',
          img: './assets/dailyQuest/daily-quest-star.png',
          total: 51,
          completed: 50,
          buttonTitle: 'Collect 51',
          buttonOnClick: () => {
            console.log('button collect 51 is clicked');
          },
        },
      ],
    });
  }, [uiElement]);

  return <>{getContent()}</>;
};

export default DailyQuestUiWrapper;
