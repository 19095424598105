import {useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {OnboardingJetpackRef} from '../../../components/animatedCharacters/onboardingJetpack/Contract';
import JetpackScriptIntegration from '../../../components/scriptIntegrations/JetpackIntegration';
import JetpackFactory, {JetpackTypes} from './JetpackFactory';

const JetpackWrapper: React.FC = () => {
  const {type, id} = useParams<{type: JetpackTypes; id: string}>();

  const characterRef = useRef<OnboardingJetpackRef>(null);
  const [jetpackLoaded, setJetpackLoaded] = useState<boolean>(false);

  return (
    <JetpackScriptIntegration
      componentId={id || 'jetpack'}
      characterRef={characterRef}
      isAnimationLoaded={jetpackLoaded}
    >
      <JetpackFactory
        type={type || 'suit'}
        properties={{
          onLoaded: () => {
            setJetpackLoaded(true);
          },
        }}
        ref={characterRef}
      />
    </JetpackScriptIntegration>
  );
};

export default JetpackWrapper;
