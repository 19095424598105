import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import {DailyQuestUiProps} from '../Types';
import DailyQuestUiSimpleLine from './DailyQuestUiSimpleLine';
import './DailyQuestUiSimple.scoped.css';

const DailyQuestUiSimple: React.FC<DailyQuestUiProps> = ({
  dailyQuests,
  onLoaded,
  onTaskCollected,
  onTaskCompleted,
}) => {
  useEffect(() => {
    onLoaded();
  }, []);

  return (
    <div className="w-full h-full relative py-[10%] md:py-[7%]">
      <motion.div
        animate={{x: [100, 0], opacity: [0, 1]}}
        transition={{duration: 0.5}}
        className="bg-white rounded-[20px] shadow-[0px_5px_25px_rgba(0,_0,_0,_0.1)] md:aspect-video flex flex-col justify-center z-10 overflow-hidden font-jost"
      >
        <h2 className="text-[#DE046D] font-bold pl-[20%] pt-[3%] text-game-2xl md:text-game-lg uppercase">
          DAILY QUEST
        </h2>
        <div className="pl-[10%] pr-[5%] flex flex-col gap-1 md:gap-6 py-[1%] md:py-[5%] overflow-scroll">
          {dailyQuests.map((quest, index) => {
            return (
              <DailyQuestUiSimpleLine
                key={index}
                img={quest.img}
                buttonTitle={quest.buttonTitle}
                completed={quest.completed}
                title={quest.title}
                total={quest.total}
                buttonOnClick={quest.buttonOnClick}
                onTaskCollected={() => onTaskCollected(quest.id)}
                onTaskCompleted={() => onTaskCompleted(quest.id)}
              />
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};
export default DailyQuestUiSimple;
