import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import {DailyQuestUiLineProps} from '../Types';
import {AiFillStar} from 'react-icons/ai';
import classNames from 'classnames';

const DailyQuestUiSimpleLine: React.FC<DailyQuestUiLineProps> = ({
  title,
  completed,
  total,
  buttonTitle,
  buttonOnClick = () => {},
  onTaskCollected,
  onTaskCompleted,
}) => {
  useEffect(() => {
    if (total === completed) {
      onTaskCompleted();
    }
  }, [completed, total]);

  return (
    <div className="flex justify-between flex-col md:flex-row gap-2 md:gap-0">
      <AiFillStar color="#DE046D" className="hidden md:block text-game-base" />
      <div className="flex flex-col gap-0 md:gap-2 w-full text-game-sm font-normal pl-0 md:pl-[2%] pr-0 md:pr-[15%]">
        <div className="text-game-lg md:text-game-sm">{title}</div>
        <div className="w-full h-[2vw] md:h-[1vw] rounded-[18px] overflow-hidden relative">
          <div className="absolute top-0 left-0 w-full h-full bg-[#DE046D] opacity-10" />
          <motion.div
            className="absolute top-0 left-0 w-full h-full bg-[#DE046D] rounded-[18px]"
            animate={{
              width: `${(completed / total) * 100}%`,
            }}
            transition={{duration: 0.5, ease: 'backInOut'}}
          />
        </div>
        <div className="w-full h-[1px] rounded-[18px] bg-black opacity-20 mt-0 xl:mt-[1%] hidden md:block" />
      </div>
      <div
        className={classNames(
          'flex items-center bg-[#DE046D] rounded-md md:rounded-[14px] text-white text-game-lg md:text-game-sm font-medium max-w-[50%] md:max-w-[24%] w-full h-fit py-[1%] justify-center self-end md:self-end',
          completed === total
            ? 'cursor-pointer hover:opacity-80'
            : 'pointer-events-none opacity-50',
        )}
        onClick={() => {
          buttonOnClick();
          onTaskCollected();
        }}
      >
        {buttonTitle}
      </div>
    </div>
  );
};
export default DailyQuestUiSimpleLine;
