import {useRef} from 'react';
import CheckListEventHandler, {
  CheckListIntegrationRef,
} from '../../../components/scriptIntegrations/checkList/CheckListEventHandler';
import CheckListUiWrapper from '../../../components/scriptIntegrations/checkList/CheckListUiWrapper';
import CheckListUiSimple from '../../../components/ui/checkList/simpleStyle/CheckListUiSimple';

const CheckListSimpleWrapper: React.FC = () => {
  const checkListRef = useRef<CheckListIntegrationRef>(null);

  return (
    <>
      <CheckListEventHandler
        ref={checkListRef}
        refresh={() => {
          console.log('refresh event');
        }}
      />
      <CheckListUiWrapper
        uiElement={CheckListUiSimple}
        onLoaded={() => checkListRef.current?.onLoaded()}
        onButtonClicked={() => checkListRef.current?.onButtonClicked()}
        onTaskChecked={(id: string) => checkListRef.current?.onTaskChecked(id)}
        onAllTasksChecked={() => checkListRef.current?.onAllTasksChecked()}
      />
    </>
  );
};

export default CheckListSimpleWrapper;
