import {useCallback} from 'react';
import {CheckListUiProps} from '../../ui/checkList/Types';

interface CheckListUiWrapperProps {
  uiElement: React.FC<CheckListUiProps>;

  onLoaded: () => void;
  onButtonClicked: () => void;
  onTaskChecked: (id: string) => void;
  onAllTasksChecked: () => void;
}

const CheckListUiWrapper: React.FC<CheckListUiWrapperProps> = ({
  uiElement,
  onLoaded,
  onButtonClicked,
  onTaskChecked,
  onAllTasksChecked,
}) => {
  const getContent = useCallback(() => {
    return uiElement({
      onLoaded,
      list: [
        {
          title: 'First One',
          id: 'asd213sg',
          group: 'Reminders',
          completed: true,
        },
        {
          title: 'Second One',
          id: '435dfg',
          group: 'Reminders',
          completed: false,
        },
        {
          title: 'Third One',
          id: 'jgh876',
          group: 'Reminders',
          completed: false,
        },
        {
          title: 'Fourth One',
          id: '93fg756',
          group: 'Reminders',
          completed: false,
        },
      ],
      onButtonClick: () => {
        console.log('Clicked on big button');
        onButtonClicked();
      },
      onAllTasksChecked: () => onAllTasksChecked(),
      onTaskChecked: (id: string) => {
        console.log('task id checked:', id);
        onTaskChecked(id);
      },
    });
  }, [uiElement, onLoaded, onAllTasksChecked, onButtonClicked, onTaskChecked]);

  return <>{getContent()}</>;
};

export default CheckListUiWrapper;
