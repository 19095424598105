import {forwardRef, useCallback, useRef, useImperativeHandle} from 'react';
import {
  CheckListCommunicatorGettingEvent,
  CheckListCommunicatorSendingEvent,
  CheckListCommunicatorSendingEventTaskChecked,
} from '../../../pages/scriptIntegrations/checkList/CommonTypes';
import EventsMessanger, {
  EventSystemRefProps,
} from '../../eventSystem/EventsMessanger';

interface CheckListIntegrationProps {
  refresh: () => void;
}

export interface CheckListIntegrationRef {
  onLoaded: () => void;
  onButtonClicked: () => void;
  onAllTasksChecked: () => void;
  onTaskChecked: (id: string) => void;
}

const CheckListEventHandler = forwardRef<
  CheckListIntegrationRef,
  CheckListIntegrationProps
>(({refresh}, ref) => {
  const eventMessangerRef =
    useRef<
      EventSystemRefProps<
        | CheckListCommunicatorSendingEvent
        | CheckListCommunicatorSendingEventTaskChecked
      >
    >(null);

  const incomingEventsHandler = useCallback(
    (message: CheckListCommunicatorGettingEvent) => {
      switch (message.event) {
        case 'refresh':
          refresh();
          return;
      }
    },
    [refresh],
  );

  useImperativeHandle(ref, () => ({
    onLoaded() {
      eventMessangerRef.current?.sendMessage({
        event: 'onLoaded',
      });
    },
    onButtonClicked() {
      eventMessangerRef.current?.sendMessage({
        event: 'onButtonClicked',
      });
    },
    onAllTasksChecked() {
      eventMessangerRef.current?.sendMessage({
        event: 'onAllTasksChecked',
      });
    },
    onTaskChecked(id: string) {
      eventMessangerRef.current?.sendMessage({
        event: 'onTaskChecked',
        id,
      });
    },
  }));

  const OnLoaded = useCallback(() => {
    eventMessangerRef.current?.sendMessage({
      event: 'onLoaded',
    });
  }, []);

  return (
    <EventsMessanger<
      CheckListCommunicatorGettingEvent,
      | CheckListCommunicatorSendingEvent
      | CheckListCommunicatorSendingEventTaskChecked
    >
      componentName="checkList"
      myRef={eventMessangerRef}
      onMessage={incomingEventsHandler}
      onLoaded={OnLoaded}
    />
  );
});

export default CheckListEventHandler;
