import {useGLTF} from '@react-three/drei';
import {useEffect, useRef, useState} from 'react';
import {RobotJetpackCharacterBase} from '../../../types/models/v2/bases/RobotJetpackCharacterBase';
import {DefaultModel} from '../types/basic';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RobotJetpackCharacterProps extends DefaultModel {}

const RobotJetpackCharacter: React.FC<RobotJetpackCharacterProps> = ({
  onLoaded,
  position,
  rotation,
  scale,
}) => {
  const group = useRef<THREE.Group>() as React.MutableRefObject<THREE.Group>;
  const {nodes, materials} = useGLTF(
    '/models/v2/fullModels/RoboJet.glb',
  ) as unknown as RobotJetpackCharacterBase;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;

    if (group.current) {
      setIsLoaded(true);
      if (onLoaded) onLoaded(group.current);
    }
  }, [group, isLoaded, onLoaded]);

  return (
    <group
      ref={group}
      dispose={null}
      position={position}
      scale={scale}
      rotation={rotation}
      receiveShadow
      castShadow
    >
      <group name="Scene">
        <group name="Armature">
          <primitive object={nodes.mixamorigHips} />
          <primitive object={nodes.neutral_bone} />
          <skinnedMesh
            name="ankle_low"
            geometry={nodes.ankle_low.geometry}
            material={materials.Robo}
            skeleton={nodes.ankle_low.skeleton}
          />
          <skinnedMesh
            name="chest_low"
            geometry={nodes.chest_low.geometry}
            material={materials.Robo}
            skeleton={nodes.chest_low.skeleton}
          />
          <skinnedMesh
            name="ear_joint_low"
            geometry={nodes.ear_joint_low.geometry}
            material={materials.Robo}
            skeleton={nodes.ear_joint_low.skeleton}
          />
          <skinnedMesh
            name="ears_low"
            geometry={nodes.ears_low.geometry}
            material={materials.Robo}
            skeleton={nodes.ears_low.skeleton}
          />
          <skinnedMesh
            name="feet_low"
            geometry={nodes.feet_low.geometry}
            material={materials.Robo}
            skeleton={nodes.feet_low.skeleton}
          />
          <skinnedMesh
            name="finger_index_low"
            geometry={nodes.finger_index_low.geometry}
            material={materials.Robo}
            skeleton={nodes.finger_index_low.skeleton}
          />
          <skinnedMesh
            name="finger_middle_low"
            geometry={nodes.finger_middle_low.geometry}
            material={materials.Robo}
            skeleton={nodes.finger_middle_low.skeleton}
          />
          <skinnedMesh
            name="finger_pinky_low"
            geometry={nodes.finger_pinky_low.geometry}
            material={materials.Robo}
            skeleton={nodes.finger_pinky_low.skeleton}
          />
          <skinnedMesh
            name="finger_thumb_low"
            geometry={nodes.finger_thumb_low.geometry}
            material={materials.Robo}
            skeleton={nodes.finger_thumb_low.skeleton}
          />
          <skinnedMesh
            name="forearm_low"
            geometry={nodes.forearm_low.geometry}
            material={materials.Robo}
            skeleton={nodes.forearm_low.skeleton}
          />
          <skinnedMesh
            name="head_eyes_low"
            geometry={nodes.head_eyes_low.geometry}
            material={materials.eyes}
            skeleton={nodes.head_eyes_low.skeleton}
          />
          <skinnedMesh
            name="head_main_low"
            geometry={nodes.head_main_low.geometry}
            material={materials.Robo}
            skeleton={nodes.head_main_low.skeleton}
          />
          <skinnedMesh
            name="head_mohawk_low"
            geometry={nodes.head_mohawk_low.geometry}
            material={materials.Robo}
            skeleton={nodes.head_mohawk_low.skeleton}
          />
          <skinnedMesh
            name="head_screen_low"
            geometry={nodes.head_screen_low.geometry}
            material={materials.Robo}
            skeleton={nodes.head_screen_low.skeleton}
          />
          <skinnedMesh
            name="heels_low"
            geometry={nodes.heels_low.geometry}
            material={materials.Robo}
            skeleton={nodes.heels_low.skeleton}
          />
          <skinnedMesh
            name="palm_low"
            geometry={nodes.palm_low.geometry}
            material={materials.Robo}
            skeleton={nodes.palm_low.skeleton}
          />
          <skinnedMesh
            name="pelvis_low"
            geometry={nodes.pelvis_low.geometry}
            material={materials.Robo}
            skeleton={nodes.pelvis_low.skeleton}
          />
          <skinnedMesh
            name="scarf_low"
            geometry={nodes.scarf_low.geometry}
            material={materials.Robo}
            skeleton={nodes.scarf_low.skeleton}
          />
          <skinnedMesh
            name="screw_low"
            geometry={nodes.screw_low.geometry}
            material={materials.Robo}
            skeleton={nodes.screw_low.skeleton}
          />
          <skinnedMesh
            name="shin_low"
            geometry={nodes.shin_low.geometry}
            material={materials.Robo}
            skeleton={nodes.shin_low.skeleton}
          />
          <skinnedMesh
            name="shoulder_low"
            geometry={nodes.shoulder_low.geometry}
            material={materials.Robo}
            skeleton={nodes.shoulder_low.skeleton}
          />
          <skinnedMesh
            name="thigh_low"
            geometry={nodes.thigh_low.geometry}
            material={materials.Robo}
            skeleton={nodes.thigh_low.skeleton}
          />
          <skinnedMesh
            name="torso_mid_low"
            geometry={nodes.torso_mid_low.geometry}
            material={materials.Robo}
            skeleton={nodes.torso_mid_low.skeleton}
          />
          <skinnedMesh
            name="upperarm_low"
            geometry={nodes.upperarm_low.geometry}
            material={materials.Robo}
            skeleton={nodes.upperarm_low.skeleton}
          />
          <skinnedMesh
            name="wrist_low"
            geometry={nodes.wrist_low.geometry}
            material={materials.Robo}
            skeleton={nodes.wrist_low.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default RobotJetpackCharacter;
