import React from 'react';
import {motion} from 'framer-motion';
import {SmallIceBreakerProps} from '../Types';

const SmallIceBreaker: React.FC<SmallIceBreakerProps> = ({
  duration = 0.5,
  xMoved,
  yMoved,
  className,
  img,
}) => {
  return (
    <motion.img
      src={img}
      className={className}
      transition={{
        duration: duration,
        ease: 'backOut',
      }}
      animate={{
        x: xMoved,
        y: yMoved,
      }}
    />
  );
};
export default SmallIceBreaker;
