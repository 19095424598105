import classNames from 'classnames';
import {useEffect} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {useResizeDetector} from 'react-resize-detector';
import {DialogProps, DialogStyle} from '../Contract';
import SimpleDialogButton from './SimpleButton';

interface SimpleDialogProps {
  props: DialogProps;
  style: DialogStyle;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  props: {
    title,
    text,
    innerButtons,

    onButtonPressed,
    onCloseButtonPressed,

    onHeightChanged,
    onWidthChanged,
  },
  style,
}) => {
  const {width, height, ref} = useResizeDetector({
    refreshRate: 200,
  });

  useEffect(() => {
    if (!height || !onHeightChanged) return;
    onHeightChanged(height);
  }, [height, onHeightChanged]);

  useEffect(() => {
    if (!width || !onWidthChanged) return;
    onWidthChanged(width);
  }, [onWidthChanged, width]);

  return (
    <div ref={ref}>
      <div
        className="relative rounded-lg cursor-default h-fit px-5 pb-2 pt-1"
        style={{
          backgroundColor: style.backgroundColor,
          color: style.mainTextColor,
          border: `1px solid ${style.borderColor}`,
        }}
      >
        <div className="flex border-b border-solid-black/80 py-1">
          {title && <div className="text-sm ">{title}</div>}
          <div
            className="ml-auto text-[#C4C5C7] hover:text-black cursor-pointer"
            onClick={onCloseButtonPressed}
          >
            <AiOutlineClose />
          </div>
        </div>

        <div className="my-2">{text}</div>
        {innerButtons && (
          <div className="flex gap-2">
            {innerButtons.map((x) => (
              <SimpleDialogButton
                key={x.id}
                className={classNames(x.flex, 'ml-auto')}
                onClick={() => onButtonPressed(x.id)}
                style={x.style}
                title={x.title}
                {...style}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleDialog;
