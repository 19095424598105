import {useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import DialogScriptIntegration, {
  DialogScriptIntegrationRef,
} from '../../../components/scriptIntegrations/DialogIntegration';
import {DialogData, DialogStyle} from '../../../components/ui/dialog/Contract';
import DialogFactory, {DialogTypes} from './DialogFactory';

const DialogWrapper: React.FC = () => {
  const {type, id} = useParams<{type: DialogTypes; id: string}>();

  const ref = useRef<DialogScriptIntegrationRef>(null);

  const [dialogProps, setDialogProps] = useState<DialogData>();
  const [dialogStyle, setDialogStyle] = useState<DialogStyle>({
    mainColor: '#808080',
    oppositeColor: '#fff',

    borderColor: '#808080',

    mainTextColor: '#000',
    backgroundColor: '#fff',
  });

  return (
    <DialogScriptIntegration
      ref={ref}
      componentId={id || 'dialog'}
      clearDialog={() => {
        setDialogProps(undefined);
      }}
      updateDialog={setDialogProps}
      updateStyle={setDialogStyle}
    >
      {dialogProps && (
        <DialogFactory
          type={type || 'simple'}
          properties={{
            ...dialogProps,
            onHeightChanged: (val) => {
              ref.current?.onHeightChanged(val);
            },
            onWidthChanged: (val) => {
              ref.current?.onWidthChanged(val);
            },
            onButtonPressed: (buttonId) => {
              ref.current?.onButtonPressed(buttonId);
            },
            onCloseButtonPressed() {
              ref.current?.onCloseButtonPressed();
            },
          }}
          style={dialogStyle}
        />
      )}
    </DialogScriptIntegration>
  );
};

export default DialogWrapper;
