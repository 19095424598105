import {useCallback} from 'react';
import {BlockBlockerUiProps} from '../../ui/blockBlocker/Types';

interface BlockBlockerUiWrapperProps {
  uiElement: React.FC<BlockBlockerUiProps>;

  onLoaded: () => void;
  onButtonClicked: () => void;
}

const BlockBlockerUiWrapper: React.FC<BlockBlockerUiWrapperProps> = ({
  uiElement,
  onLoaded,
  onButtonClicked,
}) => {
  const getContent = useCallback(() => {
    return uiElement({
      onLoaded,
      title: 'Level 16',
      subtitle: 'The level is blocked',
      text: 'Earn more stars to go further. Or unlock the level in another way',
      buttonTitle: 'Ask for help from a friend',
      onButtonClick: () => {
        console.log('Button clicked');

        onButtonClicked();
      },
    });
  }, [uiElement, onLoaded, onButtonClicked]);

  return <>{getContent()}</>;
};

export default BlockBlockerUiWrapper;
