import React from 'react';
import classNames from 'classnames';
import {StemmyDailyQuestUiTabProps} from '../Types';
import './DailyQuestUi.scoped.css';

const StemmyDailyQuestUiTab: React.FC<StemmyDailyQuestUiTabProps> = ({
  active,
  onClick,
  title,
}) => {
  return (
    <div
      className={classNames(
        ' daily-quest-button-list flex justify-center items-center py-[3%] px-0 md:px-[8%] rounded-[6px] md:rounded-[14px] cursor-pointer w-full md:w-auto',
        active ? 'z-[11]' : 'z-[9]',
      )}
      onClick={() => onClick('daily')}
    >
      <span className="font-sigmarOne text-game-2xl md:text-game-base lg:text-game-sm text-white daily-quest-title-text">
        {title}
      </span>
    </div>
  );
};
export default StemmyDailyQuestUiTab;
