import React, {useEffect, useRef, useState, useCallback} from 'react';
import {motion} from 'framer-motion';
import classNames from 'classnames';
import {TournamentProgressbarUiProps} from '../Types';
import ScrollContainer from 'react-indiana-drag-scroll';
import './TournamentProgressbarUiSimple.scoped.css';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';

const TournamentProgressbarUiSimple: React.FC<TournamentProgressbarUiProps> = ({
  tournamentProgressbar,
  onLoaded,
  onTaskCollected,
  onTaskCompleted,
}) => {
  const progressBar = useRef<any>(null);

  const [scrollLeftMax, setScrollLeftMax] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    setScrollLeftMax(progressBar.current.scrollLeftMax);
    setScrollLeft(progressBar.current.scrollLeft);
    onLoaded();
  }, []);

  const onLeftArrow = useCallback(() => {
    if (scrollLeftMax === 0) return;
    progressBar.current.scrollLeft -= 250;
  }, [scrollLeftMax]);

  const onRightArrow = useCallback(() => {
    if (scrollLeftMax === 0) return;
    progressBar.current.scrollLeft += 250;
  }, [scrollLeftMax]);

  // ф-ия проверяет есть ли рядом с обычной разметкой (с заданным шагом = tournamentProgressbar.stepForMarks) призовая разметка. Призовые полоски чуть больше, а их цифры темнее, и чтобы призовая разметка не накладывалась на обычную, мы скрываем обычную, если рядом есть призовая. Считаем что разметки находятся достаточно близко друг к другу, если расстояние между ними меньше четверти от шага обычной разметки.
  const checkNearPrize = useCallback(
    (step: number) => {
      let isShow = true;
      tournamentProgressbar.prizes.forEach((prize) => {
        if (
          Math.abs(prize.points - step) <
          tournamentProgressbar.stepForMarks / 4
        ) {
          isShow = false;
        }
      });
      return isShow;
    },
    [tournamentProgressbar.prizes, tournamentProgressbar.stepForMarks],
  );

  return (
    <div className="px-[2%] md:px-[7%] lg:px-[5%]">
      <ScrollContainer
        innerRef={progressBar}
        className="relative h-full pt-[45%] sm:pt-[25%] lg:pt-[20%] pb-[5%] whitespace-nowrap scroll-smooth cursor-pointer transition duration-300"
        draggingClassName="scale-y-105"
        onScroll={() => {
          setScrollLeft(progressBar.current.scrollLeft);
        }}
      >
        <div className="relative h-[10vw] sm:h-[6vw] lg:h-[4vw] flex items-center bg-[#DEDEDF] w-fit rounded-[70px]">
          <motion.div
            className="bg-[#DE046D] h-full w-full absolute rounded-[70px] flex justify-end min-w-fit"
            transition={{duration: 0.5, ease: 'backInOut'}}
            initial={{width: 0}}
            animate={{
              width: `${
                (tournamentProgressbar.completed /
                  tournamentProgressbar.total) *
                100
              }%`,
            }}
          >
            <div className="h-full aspect-square rounded-full bg-[#E74894] font-beVietnamPro text-white flex justify-center items-center font-extrabold text-game-base sm:text-game-sm opacity-90 z-10">
              {tournamentProgressbar.completed}
            </div>
          </motion.div>
          {new Array(
            Math.floor(
              tournamentProgressbar.total / tournamentProgressbar.stepForMarks,
            ),
          )
            .fill('')
            .map((step, index) => {
              return (
                <div
                  key={index}
                  className="min-w-[20vw] sm:min-w-[16vw] lg:min-w-[8vw] h-full relative flex items-center"
                >
                  {index !== 0 && (
                    <div
                      className={classNames(
                        'absolute h-[58%] border-2 border-solid rounded-full flex justify-center opacity-50',
                        index * tournamentProgressbar.stepForMarks <
                          tournamentProgressbar.completed
                          ? 'border-[#EF8BBB]'
                          : 'border-[#8E8E92]',
                      )}
                    >
                      {checkNearPrize(
                        index * tournamentProgressbar.stepForMarks,
                      ) && (
                        <div className="absolute top-[150%] font-beVietnamPro opacity-50 text-[#0B0B0B] text-game-lg sm:text-game-base lg:text-game-xs">
                          {index * tournamentProgressbar.stepForMarks}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          {tournamentProgressbar.prizes.map((prize) => {
            return (
              <div
                key={prize.id}
                className={classNames(
                  'absolute h-[77%] border-2 border-solid rounded-full flex justify-center',
                  prize.points < tournamentProgressbar.completed
                    ? 'border-[#EF8BBB]'
                    : 'border-[#8E8E92]',
                )}
                style={{
                  left: `${
                    (prize.points / tournamentProgressbar.total) * 100
                  }%`,
                }}
              >
                <div className="absolute top-[-34vw] sm:top-[-20vw] lg:top-[-15vw] w-[25vw] sm:w-[15vw] lg:w-[11vw] aspect-[1/1.2] rounded-[18px] bg-[#F5B3D3]">
                  <div className="px-[5%] pb-[7%] flex flex-col h-full justify-end gap-[3%]">
                    {prize.img && (
                      <img
                        src={prize.img}
                        alt="Prize gift"
                        className="object-contain object-center w-full absolute left-0 top-[-19%]"
                      />
                    )}
                    {prize.title && (
                      <p className="font-beVietnamPro text-[#0B0B0B] text-game-base sm:text-game-sm lg:text-game-xs whitespace-nowrap overflow-hidden">
                        {prize.title}
                      </p>
                    )}
                    <div
                      className="w-full bg-[#DE046D] rounded-[11px] font-beVietnamPro font-bold text-white whitespace-nowrap overflow-hidden py-[5%] px-[6%] text-game-base sm:text-game-sm lg:text-game-xs hover:bg-[#c2035f] text-center"
                      onClick={prize.onButtonClick}
                    >
                      {prize.textButton}
                    </div>
                  </div>
                </div>
                <div className="absolute top-[125%] font-beVietnamPro opacity-90 text-[#0B0B0B] text-game-lg sm:text-game-base lg:text-game-xs">
                  {prize.points}
                </div>
              </div>
            );
          })}
        </div>
      </ScrollContainer>
      {scrollLeftMax !== 0 && (
        <div className="h-full pb-[4.5%] absolute w-fit top-0 left-[0.3%] hidden md:flex items-end">
          <div
            className={classNames(
              'h-[10vw] sm:h-[6vw] lg:h-[4vw] aspect-square rounded-full font-beVietnamPro text-white flex justify-center items-center font-extrabold opacity-90 z-10',
              scrollLeft < 2
                ? 'bg-[#E9E9E9] pointer-events-none'
                : 'bg-[#E74894] cursor-pointer',
            )}
            onClick={onLeftArrow}
          >
            <BsChevronLeft />
          </div>
        </div>
      )}
      {scrollLeftMax !== 0 && (
        <div className="h-full pb-[4.5%] absolute w-fit top-0 right-[0.3%] hidden md:flex items-end">
          <div
            className={classNames(
              'h-[10vw] sm:h-[6vw] lg:h-[4vw] aspect-square rounded-full font-beVietnamPro text-white flex justify-center items-center font-extrabold opacity-90 z-10',
              scrollLeft === scrollLeftMax
                ? 'bg-[#E9E9E9] pointer-events-none'
                : 'bg-[#E74894] cursor-pointer',
            )}
            onClick={onRightArrow}
          >
            <BsChevronRight />
          </div>
        </div>
      )}
    </div>
  );
};
export default TournamentProgressbarUiSimple;
