import {useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import TournamentProgressbarEventHandler, {
  TournamentProgressbarIntegrationRef,
} from '../../../components/scriptIntegrations/tournamentProgressbar/TournamentProgressbarEventHandler';
import TournamentProgressbarUiWrapper from '../../../components/scriptIntegrations/tournamentProgressbar/TournamentProgressbarUiWrapper';
import TournamentProgressbarUiSimple from '../../../components/ui/tournamentProgressbar/simpleStyle/TournamentProgressbarUiSimple';

const TournamentProgressbarSimpleWrapper: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [userId] = useState(searchParams.get('userId') || undefined);

  const tournamentProgressbarRef =
    useRef<TournamentProgressbarIntegrationRef>(null);

  return (
    <>
      <TournamentProgressbarEventHandler
        ref={tournamentProgressbarRef}
        markTaskAsCompleted={(id) => {
          // request to server
        }}
        refresh={() => {
          // refresh from server
        }}
      />
      <TournamentProgressbarUiWrapper
        userId={userId}
        uiElement={TournamentProgressbarUiSimple}
        onLoaded={() => tournamentProgressbarRef.current?.onLoaded()}
        onTaskCompleted={(id: string) =>
          tournamentProgressbarRef.current?.onTaskCompleted(id)
        }
        onTaskCollected={(id: string) =>
          tournamentProgressbarRef.current?.onTaskCompleted(id)
        }
      />
    </>
  );
};

export default TournamentProgressbarSimpleWrapper;
